
import * as APPSETTINGS from '../constants/app';

class SESEmail {
    
    get to() { return this._to; }
    set to(x) { this._to = x; }

    get from() { return this._from; }
    set from(x) { this._from = x; }

    get subject() { return this._subject; }
    set subject(x) { this._subject = x; }

    get body() { return this._body; }
    set body(x) { this._body = x; }

    send() {
        var data = {
            to: this._to,
            from: this._from,
            subject: this._subject,
            body: this._body
        }

        fetch(APPSETTINGS.API + "/email/send", 
            {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                  'Content-Type': 'application/json'
                }
            })
            .then(Response => Response.json())
                .then(function(data) {
                    console.log(data);
                    //handle Response
                    
                    return null;
                });
    }
    
}

export default SESEmail;