import React from 'react';

const Dashboard = React.lazy(() => import('../components/Home/'));
const Invoices = React.lazy(() => import('../components/Invoices/list'));
const NewInvoice = React.lazy(() => import('../components/Invoices/invoice'));
const Admin = React.lazy(() => import('../components/.shared/Admin/'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/admin', name:'Admin', component: Admin },
  { path: '/invoices/new', name: 'New Invoice', component: NewInvoice },
  { path: '/invoices/edit/:id', name: 'Edit Invoice', component: NewInvoice },
  { path: '/invoices/:status', name: 'Invoices', component: Invoices },
  { path: '/invoices', name: 'Invoices', component: Invoices },
];

export default routes;
