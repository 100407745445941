import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROLES from '../../constants/roles';
import * as APP from '../../constants/app';
import { withFirebase } from '../.app-core/Firebase';
import { withAuthorization } from '../.app-core/Session';

import {Col, Row, Table, Card, CardHeader, CardBody, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faLink, faDollarSign, faBell} from '@fortawesome/free-solid-svg-icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import SESEmail from '../../utilities/email';
import * as APPSETTINGS from '../../constants/app';
import { formatPrice } from '../../utilities/helpers';

class InvoiceList extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, invoices:[], status: '', show: false, Toast: {Header:'', Body:''} }
    }

    componentDidMount() {
        this.setState({ loading: true });
        const { params } = this.props.match;
        
        if (params.status == null) {
            this.setState({ status: 'all'});
            this.props.firebase.invoices().on('value', snapshot => {
                const usersObject = snapshot.val();
                const usersList = Object.keys(usersObject).map(key => ({
                    ...usersObject[key],
                    uid: key,
                }));

                this.setState({
                    invoices: usersList,
                    loading: false,
                });
            });
        } else {
            this.setState({ status: params.status});
            this.props.firebase.invoices()
                .orderByChild('status').equalTo(params.status)
                .on('value', snapshot => {
                    const usersObject = snapshot.val();
                    var usersList = []; 
                    if (usersObject !== null) {
                        usersList = Object.keys(usersObject).map(key => ({
                            ...usersObject[key],
                            uid: key,
                        }));
                
                        usersList.sort((a,b ) => new Date(b["date"]) - new Date(a["date"]));

                        this.setState({
                            invoices: usersList,
                            loading: false,
                        });
                    }   
                });
        }

    }

    deleteInvoice = (id) => {
        this.props.firebase.invoice(id).remove();
    }
    
    editInvoice = (id) => {
        this.props.history.push("/invoices/edit/" + id);
    }

    copyLink = (id) => {
        this.setState({show: !this.state.show }, function() {
            setTimeout(() => { this.setState({show: !this.state.show, Toast: {Header: 'Link Copied', Body:'URL of the Invoice is on the Clipboard'} }) }, 3000);
        });
    }

    markPaid = (id) => {
        this.props.firebase.invoice(id).on('value', snapshot => {
            const usersObject = snapshot.val();
            usersObject["status"] = "paid";
            usersObject["datepaid"] = new Date().toDateString();
            usersObject["paymentmethod"] = "Admin";

            this.props.firebase.invoice(id).set(usersObject);

        });
    }

    resendEmail = (id) => {
        this.props.firebase.invoice(id).on('value', snapshot => {
            const Invoice = snapshot.val();
            var email = new SESEmail();
            email.to = Invoice["email"];
            email.from = APPSETTINGS.BILLINGEMAIL;
            email.subject = "REMINDER: Invoice " + Invoice["number"].toString() + " from Prayer Letter Service";
            email.body = "<p>" + Invoice["name"] + "</p><p>Thank You for your order from the Prayer Letter Service. " + Invoice["description"] + "</p><p><a href='"+ APPSETTINGS.DEFAULTURL + "/billing/" + id + "'>To view your invoice or pay by credit card, please click this link.</a> Thank you for using the PLS.</p><p>Sincerely, Amy @ PLS<br />524 Seventh Street<br />Rockford, IL  61104<br />815-962-7010</p>";
            email.send();

            setTimeout(() => { this.setState({show: !this.state.show, Toast: {Header: 'Reminder Sent', Body:'Email was sent to client'} }) }, 3000);
        });
    }

    render() { 
        const { invoices, loading } = this.state;
        return ( 
            <div>
                <Row className="mt-2">
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <h3 style={{textTransform: 'uppercase'}}>{this.state.status} Invoices 
                                {loading && <div>(Loading ...)</div>}
                                { !loading && <span> - {invoices.length} </span> } </h3>
                            </CardHeader>
                            <CardBody>
                                <h5>Actions:</h5>
                                <Row>
                                    <Col xs="6" md="2"><span className="btn btn-mini btn-success"><FontAwesomeIcon icon={faDollarSign} /></span> Mark Paid </Col>
                                    <Col xs="6" md="2"><span className="btn btn-mini btn-primary"><FontAwesomeIcon icon={faLink} /></span> Copy Link </Col>
                                    <Col xs="6" md="2"><span className="btn btn-mini btn-warning"><FontAwesomeIcon icon={faEdit} /></span> Edit </Col>
                                    <Col xs="6" md="2"><span className="btn btn-mini btn-info"><FontAwesomeIcon icon={faBell} /></span> Remind </Col>
                                    <Col xs="6" md="2"><span className="btn btn-mini btn-danger"><FontAwesomeIcon icon={faTrash} /></span> Delete </Col>
                                </Row>
                            </CardBody>
                            { this.state.show && 
                                <div className="p-3 my-2 rounded bg-secondary">
                                    <Toast isOpen={this.state.show} className="float-right">
                                        <ToastHeader>
                                            {this.state.Toast.Header}
                                        </ToastHeader>
                                        <ToastBody>
                                            {this.state.Toast.Body}
                                        </ToastBody>
                                    </Toast>
                                </div>
                            }
                            <InvoicesList invoices={invoices} deleteInvoice={this.deleteInvoice} editInvoice={this.editInvoice} copyLink={this.copyLink} markPaid={this.markPaid} sendRemind={this.resendEmail} />
                        </Card>
                    </Col>
                </Row> 
            </div>
        );
    }
}

const InvoicesList = ({ invoices, deleteInvoice, editInvoice, copyLink, markPaid, sendRemind }) => (
    <Row className="table-responsive">
        <CardBody>
            <Table striped responsive size="sm">
                <thead>
                    <tr>
                        <th>Invoice #</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className="small">
                    {invoices.map(i => (
                        <tr key={i.uid}>
                            <td>{i.number}</td>
                            <td>{i.date}</td>
                            <td>{i.name}</td>
                            <td>{i.description}</td>
                            <td>{formatPrice(i.amount)}</td>
                            <td style={{textTransform: 'uppercase'}}>{i.status}</td>
                            <td>
                                { i.status === 'unpaid' && (
                                    <div>
                                        <button className="btn btn-xs btn-success mr-1" onClick={(evt) => markPaid(i.uid)}>
                                            <FontAwesomeIcon icon={faDollarSign} size="xs" />
                                        </button>
                                        <CopyToClipboard text={APP.DEFAULTURL + `/billing/${i.uid}`} onCopy={() => copyLink(i.uid)}>
                                            <button className="btn btn-xs btn-primary mr-1" >
                                                <FontAwesomeIcon icon={faLink} size="xs" />
                                            </button>
                                        </CopyToClipboard>
                                        <button className="btn btn-xs btn-warning mr-1" onClick={(evt) => editInvoice(i.uid)}>
                                            <FontAwesomeIcon icon={faEdit}  size="xs" />
                                        </button>
                                        <button className="btn btn-xs btn-info mr-1" onClick={(evt) => sendRemind(i.uid)}>
                                            <FontAwesomeIcon icon={faBell} size="xs" />
                                        </button>
                                        <button className="btn btn-xs btn-danger mr-1" onClick={(evt) => deleteInvoice(i.uid)}>
                                            <FontAwesomeIcon icon={faTrash} size="xs" />
                                        </button>
                                    </div>
                                )}
                                { i.status === 'paid' && (
                                    <span>{i.datepaid} by { i.paymentmethod}</span>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
        </Table>
    </CardBody>
    </Row>
)
 

const condition = authUser => authUser && authUser.roles.length > 0 && (!!authUser.roles.includes(ROLES.ADMIN)|| !!authUser.roles.includes(ROLES.SUPERADMIN));

export default compose(withAuthorization(condition), withFirebase)(InvoiceList);