import app from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyAUWuaLLDzWTFy-hholmZGlmoQPCG8Im30",
  authDomain: "pls-billing.firebaseapp.com",
  databaseURL: "https://pls-billing.firebaseio.com",
  projectId: "pls-billing",
  storageBucket: "pls-billing.appspot.com",
  messagingSenderId: "1030048737458",
  appId: "1:1030048737458:web:bcb66304d60cb4e00281ab",
  measurementId: "G-T5V58XLJ3C"
};


class Firebase {
    constructor() {
      app.initializeApp(config);

      this.auth = app.auth();
      this.db = app.database();
      this.storage = app.storage();

      this.googleProvider = new app.auth.GoogleAuthProvider();
      
    }

    


    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignOut = () => this.auth.signOut();
    
    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
            
            // default empty roles
            if (dbUser !== null) 
            {
              if (!dbUser.roles) {
                dbUser.roles = {};
              }
            }

      
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API *** DO NOT MODIFY
  username = () => this.auth.currentUser.email;
  user = uid => this.db.ref(`admin/users/${uid}`);
  users = () => this.db.ref('admin/users');
  pending = () => this.db.ref('admin/roles');
  checkRole = email => this.db.ref(`admin/roles/${email}`);
  // app specific
  invoices = () => this.db.ref(`invoices`);
  invoice = id => this.db.ref(`invoices/${id}`);
  //file uploads
  invoiceFile = () => this.storage.ref()
}
 
export default Firebase;
  