import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../.app-core/Firebase';
import { Row, Container, Col } from 'reactstrap';
import CreditCardForm from '../.shared/credit-card-form';
import { formatPrice } from '../../utilities/helpers';

import SESEmail from '../../utilities/email';
import * as APPSETTINGS from '../../constants/app';

class BillingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, 
            invoice: {},
            validForm: false
        }
    }

    componentDidMount() {
        const { params } = this.props.match;
        if (params !== undefined && params.id !== undefined) {
            this.props.firebase.invoice(params.id).on('value', snapshot => {
                const usersObject = snapshot.val();
                console.log(usersObject);
                this.setState({
                    invoice: usersObject,
                    loading: false
                });
            });
        } else {
            this.props.history.push('/deny');
        }
    }

    recordPayment = (authorizationCode) => {
        const { params } = this.props.match;
        this.props.firebase.invoice(params.id).on('value', snapshot => {
            const usersObject = snapshot.val();
            usersObject["status"] = "paid";
            usersObject["datepaid"] = new Date().toDateString();
            usersObject["paymentmethod"] = "Credit Card";
            usersObject["authCode"] = authorizationCode;

            this.props.firebase.invoice(params.id).set(usersObject);
            //Send Email
            var email = new SESEmail();
            email.to = APPSETTINGS.BILLINGADMIN;
            email.from = APPSETTINGS.BILLINGEMAIL;
            email.subject = "Invoice " + usersObject["number"].toString() + " has been paid.";
            email.body = "<p>This is a simple notification that the invoice noted above has been paid via the PLS Billing APP.";
            email.send();

        });
    }

    render() { 
        return ( 
            <Container style={{backgroundColor:'#fff'}}>
                { this.state.loading && <Row>Loading...</Row> }
                { !this.state.loading && 
                    <Container className="mt-2">
                        <Row>
                            <div className="col-8 offset-sm-2">
                                <img className="mb-4 float-right" src="https://www.myletterservice.org/images/logo.png" alt="" width="156" />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-8 offset-sm-2">
                                <h3>Invoice #{this.state.invoice.number}</h3>
                            </div>
                            <div className="col-8 offset-sm-2 mt-5">
                                <Row>
                                    <Col size={4}>
                                        {this.state.invoice.date} <br />
                                        {this.state.invoice.name} <br />
                                        {this.state.invoice.email}
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col size={8}>
                                        <h5>Description</h5>
                                        {this.state.invoice.description}
                                    </Col>
                                    <Col>
                                        <h5>Amount</h5>
                                        {formatPrice(this.state.invoice.amount)}
                                    </Col>
                                </Row>
                                { this.state.invoice.invoiceFile !== undefined && this.state.invoice.invoiceFile !== "" &&
                                    <Row className="mt-4">
                                        <Col size={8}>
                                            <a href={this.state.invoice.invoiceFile}>Download Printable Invoice</a>
                                        </Col>
                                    </Row>
                                }
                                { this.state.invoice.status === "unpaid" &&
                                    <Row className="mt-4 mb-4">
                                        <Col size="12">
                                            <h5>Make a Payment</h5>
                                        </Col>
                                        <CreditCardForm process={this.recordPayment} amount={this.state.invoice.amount} />
                                    </Row>
                                }

                                { this.state.invoice.status === "paid" &&
                                    <ThankYou />
                                }
                            </div>
                        </Row>
                    </Container>
                }
            </Container>
         );
    }
}

const ThankYou = () => (
    <Row className="mt-4">
        <h3>This invoice has been paid.</h3>
    </Row>
)
export default compose(withFirebase)(BillingPage);