import React from 'react';
import { compose } from 'recompose';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../.app-core/Firebase';
import { withAuthorization } from '../.app-core/Session';
import { Row, Button, Form, FormGroup, Label, Input, Col, Card, CardBody, CardHeader       } from 'reactstrap';
import FormFeedback from './../.shared/form-feedback';

import SESEmail from '../../utilities/email';
import * as APPSETTINGS from '../../constants/app';

const defaultInvoice = {
    name: '',
    email: '',
    date: '',
    description: '',
    amount: 0,
    status: 'unpaid',
    number: 0,
    invoiceFile: ''
}

class Invoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false, 
            Invoice: defaultInvoice ,
            status: 'Create New Invoice', 
            uid: 0,
            FormErrors: {
                email: '',
                name: '',
                number: '',
                description: '',
                date: '',
                amount: ''
            },
            formValid: false,
            nameValid: false,
            emailValid: false,
            numberValid: false,
            dateValid: false,
            descriptionValid: false,
            amountValid: false,
            firstLoad: true
        };

        this.save = this.save.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const { params } = this.props.match;
        
        if (params.id !== undefined) {
            this.props.firebase.invoice(params.id).on('value', snapshot => {
                const usersObject = snapshot.val();
                usersObject["amount"] = usersObject["amount"]/100;
                var uid = params.id;
                this.setState({
                    Invoice: usersObject,
                    uid: uid,
                    loading: false,
                    status: 'Edit Invoice',
                    formValid: true,
                    nameValid: true,
                    emailValid: true,
                    numberValid: true,
                    dateValid: true,
                    descriptionValid: true,
                    amountValid: true
                });
            });
        }
    }

    onChange = event => {
        var Invoice = { ...this.state.Invoice };
        if (event.target.name !== "invoice") {
            Invoice[event.target.name] = event.target.value;
            this.setState({ Invoice }, this.validateField(event.target.name, event.target.value));
        } else {
            Invoice["invoiceFile"] = event.target.files[0];
            this.setState({ Invoice });
        }
      };

    submit = event => {
        var Invoice = { ...this.state.Invoice };
        //Does an Invoice Exist to upload
        if (Invoice["invoiceFile"] && typeof Invoice["invoiceFile"] !== 'string') {
            var that = this; //hack

            var fileRef = this.props.firebase.invoiceFile().child(Invoice["invoiceFile"].name);
            var uploadTask = fileRef.put(Invoice["invoiceFile"]);
            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            
            uploadTask.on('state_changed', function(snapshot){
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                // switch (snapshot.state) {
                //     case this.props.firebase.storage.TaskState.PAUSED: // or 'paused'
                //     console.log('Upload is paused');
                //     break;
                //     case this.props.firebase.storage.TaskState.RUNNING: // or 'running'
                //     console.log('Upload is running');
                //     break;
                // }
            }, function(error) {
                // Handle unsuccessful uploads
            }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    Invoice["invoiceFile"] = downloadURL;
                    that.save(Invoice);
                });
            });
        } else {
            this.save(Invoice);
        }
    }
    save(Invoice) {

        Invoice["amount"] = Invoice["amount"] * 100;
        this.setState({ Invoice }, function() {
            if (this.state.status === "Create New Invoice") {
                var newRef = this.props.firebase.invoices().push(this.state.Invoice).key;
                var email = new SESEmail();
                email.to = Invoice["email"];
                email.from = APPSETTINGS.BILLINGEMAIL;
                email.subject = "Invoice " + Invoice["number"].toString() + " from Prayer Letter Service";
                email.body = "<p>" + Invoice["name"] + "</p><p>Thank You for your order from the Prayer Letter Service. " + Invoice["description"] + "</p><p><a href='"+ APPSETTINGS.DEFAULTURL + "/billing/" + newRef + "'>To view your invoice or pay by credit card, please click this link.</a> Thank you for using the PLS.</p><p>Sincerely, Amy @ PLS<br />524 Seventh Street<br />Rockford, IL  61104<br />815-962-7010</p>";
                email.send();

            } else {
                this.props.firebase.invoice(this.state.uid).set(this.state.Invoice);
            }
            this.props.history.push('/invoices');
        });

    }

    validateField(field, value) {
        let FormErrors = this.state.FormErrors;
        let numberValid = this.state.numberValid;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let amountValid = this.state.amountValid;
        let dateValid = this.state.dateValid;
        let descriptionValid = this.state.descriptionValid;

        switch(field) {
            case 'number':
                numberValid = value.length >= 3;
                FormErrors.number = numberValid ? '' : 'Invalid Invoice Number';
                break;
            case 'amount':
                amountValid = value !== 0;
                FormErrors.amount = amountValid ? '' : 'Amount is Invalid';
                break;
            case 'date':
                dateValid = value.match("([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})");
                FormErrors.date = dateValid ? '' : 'Date is Invalid';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                FormErrors.email = emailValid ? '' : 'Email is Invalid';
                break;
            case 'name':
                nameValid = value.length >= 4;
                FormErrors.name = nameValid ? '' : 'Name is too short';
                break;
            case 'description':
                descriptionValid = value.length >= 10;
                FormErrors.descriptionValid = descriptionValid ? '' : 'Description is required';
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: FormErrors,
            numberValid: numberValid,
            nameValid: nameValid,
            emailValid: emailValid,
            amountValid: amountValid,
            dateValid: dateValid,
            descriptionValid: descriptionValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.numberValid && this.state.nameValid && this.state.emailValid && this.state.amountValid && this.state.dateValid && this.state.descriptionValid, firstLoad: false });
    }

    render() { 
        
        return ( 
            <div className="animated fadeIn">
                <Row className="mt-3">
                    
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <strong>{ this.state.status }</strong>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <h6>Details:</h6>
                                    <ul>
                                        <li>Invoice # can be determined by order management system</li>
                                    </ul>
                                    <hr />
                                </div>
                                <Form>
                                    <FormGroup row>
                                        <Label for="number" sm={2}>Invoice Number</Label>
                                        <Col sm={4}>
                                            <Input type="text" name="number" id="number" value={this.state.Invoice.number} onChange={this.onChange} valid={this.state.numberValid} />
                                        </Col>
                                        <Label for="date" sm={2}>Date</Label>
                                        <Col sm={4}>
                                            <Input type="date" name="date" id="date" value={this.state.Invoice.date} onChange={this.onChange} valid={this.state.dateValid} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={2}>Name</Label>
                                        <Col sm={4}>
                                            <Input type="text" name="name" id="name" value={this.state.Invoice.name} onChange={this.onChange} valid={this.state.nameValid} />
                                        </Col>
                                        <Label for="email" sm={2}>Email</Label>
                                        <Col sm={4}>
                                            <Input type="email" name="email" id="email" value={this.state.Invoice.email} onChange={this.onChange} valid={this.state.emailValid} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="description" sm={2}>Description</Label>
                                        <Col sm={4}>
                                            <Input type="textarea" name="description" id="description" value={this.state.Invoice.description} onChange={this.onChange} valid={this.state.descriptionValid} />
                                        </Col>
                                        <Label for="amount" sm={2}>Amount</Label>
                                        <Col sm={4}>
                                            <Input type="number" name="amount" id="amount" value={this.state.Invoice.amount} onChange={this.onChange} valid={this.state.amountValid} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="invoice" sm={2}>Invoice PDF</Label>
                                        <Col sm={4}>
                                            { this.state.status === 'Edit Invoice' && 
                                            <div className="mb-2"><a href={this.state.Invoice.invoiceFile}>Download Invoice</a> or Upload New Invoice</div>}
                                            <Input type="file" name="invoice" id="invoice" onChange={this.onChange} />
                                        </Col>
                                    </FormGroup>
                                    { !this.state.formValid && !this.state.firstLoad && 
                                            <FormFeedback status="danger" errors={this.state.FormErrors} />
                                    }
                                    <FormGroup check row>
                                        <Button onClick={this.submit} disabled={!this.state.formValid} color="primary">Submit</Button>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>  
                </Row>
            </div>
         );
    }
}
 
const condition = authUser => authUser && authUser.roles.length > 0 && (!!authUser.roles.includes(ROLES.ADMIN)|| !!authUser.roles.includes(ROLES.SUPERADMIN));

export default compose(withAuthorization(condition), withFirebase)(Invoice);